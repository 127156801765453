import './Banner2.css'

function Banner2()
{
    // JSX
    return(

        <img className="Banner2" src="/assets/BANNER2.png" alt=""></img> 

    );
}
export default Banner2