import "./Sobre.css";

function Sobre() {

  return (
    <div className="container sobre" id="sobre">
      <div className="container__imagem">
        <img
          className="sobre__imagem"
          src="/assets/Captura de tela 2024-09-04 193601.png"
          alt="Hadassa discursando num evento do partido"
        ></img>
      </div>
      <div className="container__texto">
        <h1 className="sobre__titulo">Minha História</h1>
        <p className="sobre__paragrafo">
          Hadassa Machado dos Santos, tem 30 anos e vive intensamente cada
          desafio que a vida lhe apresenta, com enfrentamento, conhecimento e
          otimismo. Nascida e criada em Arujá, cidade que tanto ama, tem muito
          orgulho de ter construído sua trajetória pessoal e profissional com
          base em valores como igualdade, justiça e inclusão “Minha paixão pelo
          conhecimento e pela defesa dos direitos me levou a atuar não só na
          área jurídica, mas também como promotora cultural, onde eu encontrei a
          oportunidade de unir minhas duas grandes paixões: cultura e a justiça
          social." Defensora incansável das causas das minorias, especialmente
          no combate a qualquer tipo de discriminação, sendo ela mesma uma
          mulher que vive com sua deficiência. “Minha experiência pessoal me deu
          uma perspectiva única sobre a importância de políticas públicas que
          promovam a acessibilidade e a inclusão de todos os cidadãos,
          independentemente de suas condições físicas, mentais ou sociais.” E é
          com essa força e determinação que tenho desde meu primeiro dia de vida
          que me coloco à disposição para representar os interesses de cada
          cidadão arujaense na Câmara Municipal. “Meu objetivo como vereadora é
          ser uma voz ativa na defesa dos direitos de todos, trabalhando
          incansavelmente por uma Arujá mais justa, inclusiva e próspera para as
          futuras gerações. Quero levar ao Legislativo Municipal a mesma
          energia, comprometimento e paixão que já dedico as minhas atividades
          profissionais culturais.”
        </p>
      </div>
    </div>
  );
}
export default Sobre;
