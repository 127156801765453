import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [toggleIcon, setToggleIcon] = useState("nav__toggler");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Verifica o tamanho da tela para determinar se é móvel
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768); // Defina seu ponto de corte conforme necessário
    };

    // Executa a verificação ao montar o componente
    checkScreenWidth();

    // Adiciona um listener para verificar quando o tamanho da tela mudar
    window.addEventListener("resize", checkScreenWidth);

    // Remove o listener ao desmontar o componente para evitar vazamentos de memória
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const navToggle = () => {
    setActive(active === "nav__menu" ? "nav__menu nav__active" : "nav__menu");
    setToggleIcon(
      toggleIcon === "nav__toggler" ? "nav__toggler toggle" : "nav__toggler"
    );
  };

  // Fechar o menu ao escolher uma opção
  const closeMenu = () => {
    setActive("nav__menu");
    setToggleIcon("nav__toggler");
  };

  // Fechar o menu ao rolar a página, apenas em dispositivos móveis
  useEffect(() => {
    const handleScroll = () => {
      if (isMobile && active === "nav__menu nav__active") {
        setActive("nav__menu");
        setToggleIcon("nav__toggler");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile, active]);

  return (
    <nav className="nav">
      <a href="/">
        <img src="/assets/LOGO-03.png" alt="Logo" className="logo" />
      </a>
      <ul className={active}>
        <li className="nav__item">
          <a href="/" className="nav__link" onClick={closeMenu}>
            PÁGINA INICIAL
          </a>
        </li>
        <li className="nav__item">
          <Link to="/propostas" className="nav__link" onClick={closeMenu}>
            PROPOSTAS
          </Link>
        </li>
        <li className="nav__item">
          <a
            href="https://www.basepolitica.com.br/principal/index.php/inscricao/4658397"
            className="nav__link"
            target="blank"
            onClick={closeMenu}
          >
            SEJA UM APOIADOR
          </a>
        </li>
      </ul>
      <div onClick={navToggle} className={toggleIcon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;
