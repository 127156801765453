import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Navbar from './componentes/Navbar/Navbar';
import Banner from './componentes/Banner/Banner';
import Sobre from './componentes/Sobre/Sobre';
import IndexCards from './componentes/IndexCards/IndexCards';
import Carrossel from './componentes/Carrossel/Carrossel';
import RedeSocial from './componentes/RedeSocial/RedeSocial';
import Banner2 from './componentes/Banner2/Banner2';
import Propostas from './componentes/Propostas/Propostas'; //

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <Banner />
            <Sobre />
            <IndexCards />
            <Carrossel />
            <RedeSocial />
            <Banner2 />
          </Route>
          <Route path="/propostas">
            <Propostas /><Banner2 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;