import React, { useEffect } from "react";
import "./Imagens.css";

// Lista de imagens
const images = [
  "/assets/imagens/img2.png",
  "/assets/imagens/img3.png",
  "/assets/imagens/img4.png",
  "/assets/imagens/img5.png",
  "/assets/imagens/img6.png",
  "/assets/imagens/img7.png",
  "/assets/imagens/img8.png",
  "/assets/imagens/img9.png",
  "/assets/imagens/img10.png",
  "/assets/imagens/img11.png",
  "/assets/imagens/img12.png",
  "/assets/imagens/img13.png",
  "/assets/imagens/img14.png",
  "/assets/imagens/img15.png",
  "/assets/imagens/img16.png",
  "/assets/imagens/img17.png",
  "/assets/imagens/img18.png",
  "/assets/imagens/img19.png",
  "/assets/imagens/img20.png",
  "/assets/imagens/img21.png",
  "/assets/imagens/img22.png",
  "/assets/imagens/img23.png",
  "/assets/imagens/img24.png",
  "/assets/imagens/img25.png",
  "/assets/imagens/img26.png",
  "/assets/imagens/img27.png",
  "/assets/imagens/img28.png",
  "/assets/imagens/img29.png",
  "/assets/imagens/img30.png",
  "/assets/imagens/img31.png",
  "/assets/imagens/img32.png",
  "/assets/imagens/img33.png",
  "/assets/imagens/img34.png",
  "/assets/imagens/img35.png",
  "/assets/imagens/img36.png",
  "/assets/imagens/img37.png",
  "/assets/imagens/img38.png",
  "/assets/imagens/img39.png",
  "/assets/imagens/img40.png",
  "/assets/imagens/img41.png",
  "/assets/imagens/img42.png",
  "/assets/imagens/img43.png",
  "/assets/imagens/img44.png",
  "/assets/imagens/img45.png",
  "/assets/imagens/img46.png",
  "/assets/imagens/img47.png",
  "/assets/imagens/img48.png",
  "/assets/imagens/img49.png",
  "/assets/imagens/img50.png",
  "/assets/imagens/img51.png",
  "/assets/imagens/img52.png",
  "/assets/imagens/img53.png",
  "/assets/imagens/img54.png",
  "/assets/imagens/img55.png",
  "/assets/imagens/img56.png",
  "/assets/imagens/img57.png",
  "/assets/imagens/img58.png",
  "/assets/imagens/img59.png",
  "/assets/imagens/img60.png",
  "/assets/imagens/img61.png",
  "/assets/imagens/img62.png"
];

const Modal = ({ isOpen, onClose }) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        onClose(); // Fechar modal ao pressionar ESC
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleEscape);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          Fechar
        </button>
        <h2 className="modal__title">Galeria de Fotos</h2>
        <p className="sobre__paragrafo">
          Sozinhos, podemos fazer pouco. Juntos, podemos fazer muito.
        </p>
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="5000" // Ajuste o intervalo de autoplay
        >
          <div className="carousel-inner">
            {images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img
                  src={image}
                  className="d-block w-100"
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Anterior</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Próximo</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;