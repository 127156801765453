import "./Carrossel.css";

function Carrossel() {
  return (
    <div className="container container__carrosel">
      <div className="container__texto">
        <h2 className="carrossel__titulo">Galeria de Vídeos</h2>
        <p className="sobre__paragrafo">
          Aqui você encontrará uma seleção de vídeos que refletem as visões e
          ideias de Hadassa. Através desses vídeos, Hadassa compartilha sua
          perspectiva sobre o mundo em suas redes sociais.
        </p>
      </div>
      <div className="carrossel__container">
        <div
          id="videoCarousel"
          className="carousel slide"
          data-bs-ride="false" // Desativa o autoplay
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="video-container">
                <video className="d-block" controls>
                  <source src="/assets/video1.mp4" type="video/mp4" />
                  Seu navegador não suporta reprodução de vídeos.
                </video>
              </div>
            </div>

            <div className="carousel-item">
              <div className="video-container">
                <video className="d-block" controls>
                  <source src="/assets/video2.mp4" type="video/mp4" />
                  Seu navegador não suporta reprodução de vídeos.
                </video>
              </div>
            </div>
            <div className="carousel-item">
              <div className="video-container">
                <video className="d-block" controls>
                  <source src="/assets/video3.mp4" type="video/mp4" />
                  Seu navegador não suporta reprodução de vídeos.
                </video>
              </div>
            </div>
            <div className="carousel-item">
              <div className="video-container">
                <video className="d-block" controls>
                  <source src="/assets/video4.mp4" type="video/mp4" />
                  Seu navegador não suporta reprodução de vídeos.
                </video>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#videoCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#videoCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Carrossel;
