import React, { useState } from "react";
import "./Propostas.css";

const NovaPagina = () => {
  // Estado para manter o controle do item ativo
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    // Define o índice do item clicado como o item ativo
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container">
      <div className="accordion">
        <div
          className={`box__conteudo ${activeIndex === 0 ? "active" : ""}`}
          onClick={() => handleClick(0)}
        >
          <div className="label">Projetos Para a Educação</div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Formação Continuada e Valorização dos Servidores Públicos
            </h3>
            <p className="paragrafo__accordion">
              <b>Projeto Lei - Estruturar Programa de Formação Continuada: </b>
              Criação de um programa de formação continuada para os servidores
              públicos de todos os setores, com foco no desenvolvimento de
              competências, aprimoramento profissional e plano de carreira. Esse
              projeto visa garantir que os servidores estejam sempre atualizados
              e capacitados para atender às demandas da população de forma
              eficiente e qualificada.
            </p>
            <p className="paragrafo__accordion">
              <b>Projeto Lei - Prêmios de Valorização Profissional: </b>
              Instituição de prêmios anuais que reconheçam e valorizem os
              profissionais da educação que se destacarem por suas práticas
              inovadoras na educação inclusiva.
            </p>
            <p className="paragrafo__accordion">
              <b>Projeto Lei - Educação Multidisciplinar: </b> Incentivo a
              projetos que promovam a educação multidisciplinar nas escolas
              municipais, integrando diferentes áreas do conhecimento e
              preparando os estudantes para os desafios do mundo contemporâneo.
            </p>
            <h3 className="subtitulo__accordion">Apoio ao Ensino Superior</h3>
            <p className="paragrafo__accordion">
              <b>Transporte Gratuito para Universitários: </b> Proposta de
              criação de uma associação de estudantes, em parceria com o setor
              público e privado, para oferecer transporte gratuito aos
              universitários que precisam estudar fora do município. Este
              projeto visa garantir que todos os estudantes de Arujá tenham
              acesso ao ensino superior, independentemente de suas condições
              financeiras.
            </p>
          </div>
        </div>
        <div
          className={`box__conteudo ${activeIndex === 1 ? "active" : ""}`}
          onClick={() => handleClick(1)}
        >
          <div className="label">Projetos para a Saúde</div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Projeto Lei que institui o Programa Municipal de Saúde Preventiva
            </h3>
            <p className="paragrafo__accordion">
              <b>O PL Cria do Programa Municipal de Saúde Preventiva: </b>{" "}
              Propõe a criação de um programa municipal focado em saúde
              preventiva, que inclua campanhas de vacinação, check-ups
              regulares, e a promoção de hábitos saudáveis. Esse programa deve
              priorizar a medicina familiar e a atenção básica, com equipes
              multidisciplinares visitando regularmente as comunidades,
              especialmente nas áreas periféricas e rurais, para garantir que
              todos tenham acesso aos cuidados necessários.
            </p>
            <p className="paragrafo__accordion">
              <b>Medicina Itinerante: </b>Implementação de um sistema de
              medicina itinerante, onde equipes de saúde se desloquem até os
              bairros mais distantes para realizar consultas e exames
              preventivos. Essa abordagem visa aproximar o atendimento de saúde
              das pessoas que têm dificuldade de acesso aos serviços
              tradicionais, garantindo que todos recebam os cuidados
              necessários.
            </p>
            <h3 className="subtitulo__accordion">Apoio à Saúde Mental</h3>
            <p className="paragrafo__accordion">
              <b>Fortalecimento dos Serviços de Saúde Mental: </b> Propor a
              estruturação e ampliação dos serviços de saúde mental no
              município, garantindo acesso a terapias e tratamentos para
              pacientes crônicos e oferecendo atendimento inicial de qualidade.
              Isso inclui a valorização dos profissionais de psicologia e
              psiquiatria, além da capacitação contínua desses profissionais
              para um atendimento mais humanizado e eficaz.
            </p>
            <p className="paragrafo__accordion">
              <b>Programas de Apoio à Vulnerabilidade Psiquiátrica: </b> Propor
              a criação de programas específicos de apoio para pessoas em
              situação de vulnerabilidade psiquiátrica, oferecendo suporte
              contínuo e encaminhamento para os serviços adequados.
            </p>
            <p className="paragrafo__accordion">
              <b>Humanização do Atendimento nos Postos de Saúde: </b>
              Desenvolver e implementar um projeto de humanização do atendimento
              nos postos de saúde e pronto-atendimentos, que inclua a
              capacitação dos profissionais de saúde para oferecer um
              atendimento mais empático e acolhedor. Esse projeto também deve
              contemplar a saúde mental dos próprios profissionais, oferecendo
              suporte psicológico e condições de trabalho que favoreçam seu
              bem-estar.
            </p>
          </div>
        </div>

        <div
          className={`box__conteudo ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => handleClick(2)}
        >
          <div className="label"> Projetos para o Meio Ambiente</div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Preservação Ambiental em Arujá: A Cidade Natureza
            </h3>
            <p className="paragrafo__accordion">
              <b>Arujá - Cidade de Manancial e Mata Nativa:</b> Arujá é
              conhecida como a "Cidade Natureza", abrigando mananciais e
              corredores verd es que são vitais para a biodiversidade e a
              qualidade de vida de seus habitantes. Proponho a criação de
              projetos que reforcem a preservação dessas áreas, protegendo e
              mantendo nosso patrimônio ambiental para as futuras gerações.
            </p>
            <p className="paragrafo__accordion">
              <b>Programa de Plantio e Reflorestamento Urbano: </b>Propor um
              programa municipal de plantio de árvores nativas, • Incentivo à
              Agricultura Familiar Fortalecimento da Agricultura Familiar: Arujá
              é um local de grande cultivo de agricultura familiar, que
              desempenha um papel essencial na produção de alimentos frescos e
              na economia local. Propor projetos de apoio técnico e financeiro
              para os pequenos agricultores, fornecendo assistência em práticas
              agrícolas sustentáveis, gestão de recursos e acesso ao mercado.
              planejado estrategicamente para que, no futuro, quando árvores
              mais antigas precisarem ser removidas, já existam árvores jovens e
              maduras para substituir essas perdas. Isso garantirá a
              continuidade dos benefícios ambientais que as árvores
              proporcionam, como a melhoria da qualidade do ar, a regulação da
              temperatura e a proteção do solo.
            </p>
            <p className="paragrafo__accordion">
              <b>Proteção e Expansão do Corredor Verde: </b>Estudar e propor
              medidas que protejam e expandam o corredor verde de Arujá,
              assegurando que as áreas de mata nativa sejam preservadas e que
              novos espaços verdes sejam integrados ao planejamento urbano. Isso
              inclui a criação de parques e reservas que mantenham a
              biodiversidade local.
            </p>
            <h3 className="subtitulo__accordion">
              Incentivo à Agricultura Familiar
            </h3>
            <p className="paragrafo__accordion">
              <b>Fortalecimento da Agricultura Familiar: </b>Arujá é um local de
              grande cultivo de agricultura familiar, que desempenha um papel
              essencial na produção de alimentos frescos e na economia local.
              Propor projetos de apoio técnico e financeiro para os pequenos
              agricultores, fornecendo assistência em práticas agrícolas
              sustentáveis, gestão de recursos e acesso ao mercado.
            </p>
            <p className="paragrafo__accordion">
              <b>Programas de Capacitação e Incentivos: </b>Implementar
              programas de capacitação que ajudem os pequenos agricultores a
              melhorar sua produtividade e sustentabilidade, utilizando técnicas
              modernas e eficientes. Esses programas devem incluir o acesso a
              insumos de qualidade, tecnologias inovadoras e práticas que
              respeitem o meio ambiente.
            </p>
            <p className="paragrafo__accordion">
              <b>Feiras e Mercados de Produtos Locais: </b>Propor a criação de
              feiras e mercados exclusivos para os produtos da agricultura
              familiar, incentivando a população a consumir alimentos locais,
              frescos e sustentáveis. Essa iniciativa não só fortalece a
              economia local, mas também promove a alimentação saudável e a
              conexão entre os produtores e a comunidade.
            </p>
            <h3 className="subtitulo__accordion">
              Proteção dos Animais Silvestres e Combate às Queimadas
            </h3>
            <p className="paragrafo__accordion">
              <b>Proteção dos Animais Silvestres: </b>Propor medidas para a
              proteção da fauna silvestre em Arujá, assegurando que os habitats
              naturais sejam preservados e que haja campanhas educativas sobre a
              importância da preservação dos animais nativos. Incentivar
              parcerias com ONGs e órgãos ambientais para monitoramento e
              resgate de animais em risco.
            </p>
            <p className="paragrafo__accordion">
              <b>Prevenção e Combate às Queimadas: </b>Desenvolver um programa
              de prevenção contra queimadas, especialmente nas áreas rurais e de
              mata nativa, que inclua a educação da população sobre os riscos e
              as penalidades envolvidas. Além disso, propor a criação de
              brigadas de incêndio especializadas para agir rapidamente no
              combate aos focos de incêndio, minimizando os danos ambientais.
            </p>
            <p className="paragrafo__accordion">
              <b>Fiscalização de Loteamentos Ilegais: </b>Intensificar a
              fiscalização nas áreas rurais para prevenir e combater o
              crescimento de loteamentos ilegais, que ameaçam as áreas de
              preservação e o equilíbrio ambiental de Arujá. Propor leis mais
              rígidas e a criação de uma força-tarefa para monitorar e agir
              contra essas práticas irregulares.
            </p>
          </div>
        </div>

        <div
          className={`box__conteudo ${activeIndex === 3 ? "active" : ""}`}
          onClick={() => handleClick(3)}
        >
          <div className="label">
            Projetos para a Cultura e Valorização do Artista Local
          </div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Lei de Incentivo aos Artistas Locais
            </h3>
            <p className="paragrafo__accordion">
              <b>Criação de uma Lei Municipal de Incentivo à Cultura: </b>Propor
              a criação de uma lei de incentivo à cultura em Arujá, que permita
              aos artistas locais obter patrocínios de empresas, indústrias e
              comércios, com abatimento de impostos para os patrocinadores. Essa
              medida visa fomentar a produção cultural no município, dando aos
              artistas o suporte necessário para desenvolverem suas carreiras e
              contribuírem para o enriquecimento cultural da cidade.
            </p>
            <h3 className="subtitulo__accordion">
              Desenvolvimento de Projetos e Festivais Culturais
            </h3>
            <p className="paragrafo__accordion">
              <b>Valorização da Cultura Local: </b>Propor a criação e o
              desenvolvimento de projetos culturais e festivais que destaquem e
              celebrem a cultura local. Esses eventos servirão como vitrine para
              os artistas de Arujá, permitindo que eles mostrem seu trabalho e
              fortaleçam a identidade cultural da cidade.
            </p>
            <p className="paragrafo__accordion">
              <b>Resgate e Fortalecimento das Tradições Locais: </b>Incentivar a
              retomada de tradições culturais que a cidade já possuía, como
              festas populares, folclore e artesanato, integrando essas
              manifestações em festivais e eventos que promovam a cultura de
              Arujá em suas diversas formas.
            </p>
            <p className="paragrafo__accordion">
              <b>
                Projeto de Lei que cria o piso para pagamento de cachês
                artísticos locais:
              </b>
              Instituição do piso para cachê nos diversos segmentos modalidades
              artísticas.
            </p>
            <h3 className="subtitulo__accordion">
              Incentivo à Multidisciplinaridade Cultural
            </h3>
            <p className="paragrafo__accordion">
              <b>Cultura Multidisciplinar: </b>Apoiar e promover projetos
              culturais que integrem diferentes formas de expressão artística,
              como música, teatro, dança, literatura, e artes visuais, em uma
              abordagem multidisciplinar. A ideia é fomentar a inovação e a
              colaboração entre artistas de diferentes áreas, criando uma cena
              cultural rica e diversificada em Arujá.
            </p>
            <p className="paragrafo__accordion">
              <b>Oficinas de Formação Para Artistas: </b>Propor a criação de
              oficinas e programas de capacitação para artistas e jovens
              talentos da cidade, oferecendo oportunidades de formação e
              desenvolvimento em diversas áreas culturais. Isso inclui a
              realização de workshops, cursos e palestras que incentivem a
              profissionalização e a inovação na cultura local.
            </p>
          </div>
        </div>

        <div
          className={`box__conteudo ${activeIndex === 4 ? "active" : ""}`}
          onClick={() => handleClick(4)}
        >
          <div className="label">
            Projetos para Mobilidade Urbana, Acessibilidade e Inclusão
          </div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Fiscalização e Melhoria da Acessibilidade
            </h3>
            <p className="paragrafo__accordion">
              <b>Fiscalização Rigorosa de Ambientes Públicos e Privados: </b>
              Como pessoa com deficiência, assumo o compromisso de fiscalizar
              rigorosamente a acessibilidade em todos os ambientes públicos e
              privados de Arujá. Propor medidas que garantam que todas as
              construções, reformas e novos empreendimentos cumpram as normas de
              acessibilidade, assegurando que todos os cidadãos tenham acesso
              pleno e igualitário aos espaços da cidade.arantir que todos tenham
              acesso aos cuidados necessários.
            </p>
            <p className="paragrafo__accordion">
              <b>Inclusão Universal: </b>Trabalhar para que todos os espaços
              públicos de Arujá sejam inclusivos e acessíveis, garantindo que
              pessoas com deficiência possam participar ativamente da vida
              social, cultural e econômica da cidade. Isso inclui a adaptação de
              escolas, centros de saúde, prédios públicos e áreas de lazer para
              que sejam acessíveis a todos.
            </p>
            <h3 className="subtitulo__accordion">
              Mobilidade Urbana Sustentável e Inclusiva
            </h3>
            <p className="paragrafo__accordion">
              <b>Estudo e Planejamento de Trânsito: </b>Propor e apoiar estudos
              de mobilidade urbana que mapeiem e analisem o trânsito da cidade,
              buscando soluções que melhorem o fluxo de veículos, reduzam os
              congestionamentos e aumentem a segurança nas vias. Esse
              planejamento deve incluir medidas para facilitar o deslocamento de
              pedestres e ciclistas, promovendo uma mobilidade mais sustentável
              e eficiente.
            </p>
            <p className="paragrafo__accordion">
              <b>Melhoria das Calçadas e Infraestrutura Viária: </b>Propor a
              melhoria e manutenção das calçadas, especialmente nas áreas
              centrais e nos bairros, assegurando que sejam acessíveis e seguras
              para todos os cidadãos. Isso inclui a implementação de rampas,
              faixas de pedestres elevadas e calçadas mais largas e niveladas.
            </p>
            <p className="paragrafo__accordion">
              <b>Calçamento das Áreas Rurais: </b>Trabalhar para que as áreas
              rurais de Arujá também recebam infraestrutura de calçamento
              adequada, facilitando o acesso dos moradores e promovendo a
              integração dessas áreas com o restante da cidade. Esse calçamento
              deve considerar a preservação do ambiente rural e as necessidades
              específicas dos moradores.
            </p>
            <h3 className="subtitulo__accordion">
              Ciclovias e Incentivo ao Ecoturismo
            </h3>
            <p className="paragrafo__accordion">
              <b>Implementação de Ciclovias: </b>Propor a criação de ciclovias
              que conectem as áreas centrais da cidade aos bairros e áreas
              rurais, promovendo o uso da bicicleta como meio de transporte
              seguro e sustentável. As ciclovias devem ser integradas ao
              planejamento urbano, incentivando uma mobilidade mais saudável e
              ecológica.
            </p>
            <p className="paragrafo__accordion">
              <b>Incentivo ao Ecoturismo: </b>Aproveitar as características
              naturais de Arujá para promover o ecoturismo, utilizando as
              ciclovias e trilhas como atrativos para moradores e visitantes.
              Isso inclui o desenvolvimento de roteiros que valorizem as belezas
              naturais da cidade e incentivem práticas sustentáveis.
            </p>
          </div>
        </div>

        <div
          className={`box__conteudo ${activeIndex === 5 ? "active" : ""}`}
          onClick={() => handleClick(5)}
        >
          <div className="label">Projetos para a Saúde</div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Projeto Lei que institui o Programa Municipal de Saúde Preventiva
            </h3>
            <p className="paragrafo__accordion">
              <b>O PL Cria do Programa Municipal de Saúde Preventiva: </b>{" "}
              Propõe a criação de um programa municipal focado em saúde
              preventiva, que inclua campanhas de vacinação, check-ups
              regulares, e a promoção de hábitos saudáveis. Esse programa deve
              priorizar a medicina familiar e a atenção básica, com equipes
              multidisciplinares visitando regularmente as comunidades,
              especialmente nas áreas periféricas e rurais, para garantir que
              todos tenham acesso aos cuidados necessários.
            </p>
          </div>
        </div>

        <div
          className={`box__conteudo ${activeIndex === 6 ? "active" : ""}`}
          onClick={() => handleClick(6)}
        >
          <div className="label">
            Compromisso com a Transparência e Abertura ao Diálogo
          </div>
          <div className="conteudo">
            <h3 className="subtitulo__accordion">
              Gabinete Aberto aos Cidadãos
            </h3>
            <p className="paragrafo__accordion">
              <b>Abertura do Gabinete aos Finais de Semana: </b>Comprometo-me,
              caso eleita, a abrir o meu gabinete na Câmara Municipal pelo menos
              um f inal de semana por mês. Essa iniciativa visa garantir que
              todos os trabalhadores, especialmente aqueles que têm horários
              comerciais restritos, possam acessar diretamente o vereador,
              expressar suas preocupações, fazer sugestões e acompanhar de perto
              as atividades legislativas. O objetivo é criar um canal de
              comunicação direta e constante entre o vereador e os cidadãos.
            </p>
            <h3 className="subtitulo__accordion">
              Democratização do Processo Legislativo
            </h3>
            <p className="paragrafo__accordion">
              Transparência nas Votações: Propor e implementar mecanismos para
              aumentar a transparência e acessibilidade nas votações da Câmara.
              Isso inclui a divulgação antecipada das pautas e projetos a serem
              votados, bem como o resultado das votações, de forma clara e
              acessível em todas as plataformas digitais.
            </p>

            <p className="paragrafo__accordion">
              <b>Participação Cidadã nas Redes Sociais: </b>Utilizar as redes sociais e
              outras plataformas digitais para levar os debates do plenário ao
              conhecimento da população, promovendo enquetes, debates online e
              sessões ao vivo, para que o cidadão arujaense tenha voz ativa e
              possa participar do processo legislativo de maneira mais próxima e
              direta. Essa proposta visa fortalecer a democracia participativa,
              permitindo que os cidadãos influenciem as decisões que impactam
              diretamente suas vidas.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NovaPagina;
