import "./RedeSocial.css";

function RedeSocial() {
  return (
    <div className="container">
      <div className="container_generico">
        <div>
          <h2 className="titulo__secao">Siga ela nas redes</h2>
        </div>
        <div className="container__icone__rede">
          <div className="icone__rede">
            <a href="https://www.instagram.com/falecomhadassa/" target="blank">
              <img src="/assets/instagram.png" alt="Ícone clicável que direciona o usuário ao instagram da Hadassa"></img>
            </a>
          </div>
          <div className="icone__rede">
            <a href="https://www.tiktok.com/@hadassamachads" target="blank">
              <img src="/assets/tiktok.png" alt="Ícone clicável que direciona o usuário ao TikTok da Hadassa"></img>
            </a>
          </div>
          <div className="icone__rede">
            <a href="https://www.facebook.com/falecomhadassa" target="blank">
              <img src="/assets/facebook.png" alt="Ícone clicável que direciona o usuário ao Facebook da Hadassa"></img>
            </a>
          </div>
          <div className="icone__rede">
            <a href="https://wa.me/+5511998693119" alt="Ícone clicável que direciona o usuário ao whatsapp da Hadassa" target="blank">
              <img src="/assets/whatsapp.png"></img>
            </a>
          </div>
          
        </div>
      </div>
    </div>
  );
}
export default RedeSocial;
