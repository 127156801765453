import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./IndexCards.css";
import Modal from "./Imagens"; // Certifique-se de ajustar o caminho conforme necessário

function IndexCards() {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="container">
      <div className="card__container">
        <Link to="/propostas">
          <div className="card">
            <img
              src="/assets/arte-propostas-galeria01.png"
              className="card-img-top"
              alt="Card para acessar a tela de propostas"
            ></img>
            <div className="card-body">
              <h2 className="card-title">Minhas Propostas</h2>
            </div>
          </div>
        </Link>
        <a href="#" onClick={openModal}>
          <div className="card">
            <img
              src="/assets/arte-propostas-galeria02.png"
              className="card-img-top"
              alt="Card para acessar a galeria de fotos com os apoiadores"
            ></img>
            <div className="card-body">
              <h2 className="card-title">Galeria de Fotos</h2>
            </div>
          </div>
        </a>
      </div>
      <Modal isOpen={modalOpen} onClose={closeModal} />
    </div>
  );
}

export default IndexCards;
