import './Banner.css'

function Banner()
{
    // JSX
    return(

        <img className="Banner" src="/assets/BANNER-SITE-CAMPANHA-1920X1080.png" alt="Banner da campanha com as fotos da canditada a vereadora Hadassa e do candidato a prefeito Camargo"></img> 

    );
}
export default Banner